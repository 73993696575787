import React from 'react';
import warning from '../assets/images/web-warning.png';

const WebWarning = () => {
  return (
    <div className="home-bg web-bg">
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', margin: 'auto'}}>
        <img src={warning} alt="Web Warning" style={{ width: '30%', minWidth: '500px' }} />
      </div>
    </div>
  );
};

export default WebWarning;
