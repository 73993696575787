import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import { lazy, Suspense } from 'react';
import WebWarning from './components/WebWarning.js';
import { useState, useEffect } from 'react';

const Loading = lazy(() => import('./components/Loading.js'));
const Login = lazy(() => import('./pages/Login.js'));
const Verify = lazy(() => import('./pages/Verify.js'));
const Register = lazy(() => import('./pages/Register.js'));
const Home = lazy(() => import('./pages/Home.js'));
const Upload = lazy(() => import('./pages/Upload.js'));
const Rewards = lazy(() => import('./pages/Rewards.js'));

function App() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  if (isDesktop) {
    return <WebWarning />;
  }
  
  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/register" element={<Register />} />
            <Route path="/home" element={<Home />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/rewards" element={<Rewards />} />
            {/* Default */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
